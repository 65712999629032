/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it

exports.onRouteUpdate = () => {
    if (typeof window !== `undefined`) { window.scrollTo(0, 0) }
}

exports.shouldUpdateScroll = args => {
    return false;
};

// Execute this function when the route changes
exports.onRouteUpdate = ({ location }) => {
    // Mapping of old relative paths to new relative paths
    const pathRedirects = {
      "/ism/about-ism/": "/ism/burden-of-ism/",
      "/ism/resources/": "/ism/resources/access-and-support/",
      "/ism/#sign-up": "/ism/connect-with-us/"
    };
  
    // Get the current path including hash
    const currentPath = location.pathname + location.hash;
  
    // Check if the current path matches any of the old relative paths
    if (pathRedirects[currentPath]) {
      // Redirect to the new relative path
      window.location.pathname = pathRedirects[currentPath];
    }
  };
  